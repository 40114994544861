<script>
import {
  geneticPatternsDataType,
  geneticPatternsDataTypeOptions,
  categoriesAnimalDataType,
  categoriesAnimalDataTypeOptions,
  categoriesComplementaryDataType,
  categoriesComplementaryDataTypeOptions,
  supplementDataType,
  supplementDataTypeOptions,
  weighingDataType,
  weighingDataTypeOptions,
  movementDataType,
  movementDataTypeOptions,
  movementCategoryDataType,
  movementCategoryDataTypeOptions,
  daysOccupationDataType,
  daysOccupationDataTypeOptions } from '@/utils/enums'
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import gql from 'graphql-tag';
import cloneDeep from 'lodash/cloneDeep';
import toInt from 'lodash/toInteger';
import toNumber from 'lodash/toNumber';
import moment from 'moment';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

import Register from './RegisterDialog.vue'


dayjs.extend(utc)
dayjs.extend(timezone)

const QUERY_BATCH = gql`
  query batch($id: uuid!) {
    batch: batches_by_pk(id: $id) {
      id
      code
      name
      status
      number_of_heads
      average_weight
      expected_performance
      customer {
        id
        name
      }
      genetic_pattern
      category_animal
      category_complementary
      day_occupation
      supplement_mineral_consumption
      supplement_mineral_description
      supplement_mineral_pb
      supplement_mineral_ndt 
      supplement_feed_consumption
      supplement_feed_description
      supplement_feed_pb
      supplement_feed_ndt
      supplement_feed_fdn
      supplement_vol_consumption
      supplement_vol_description
      supplement_vol_ms
      supplement_vol_pb
      supplement_vol_em
      supplement_vol_fdn
      supplement_enabled
      main_activity
      module {
        id
        name
        farm {
          id
          name
          id
        }
        paddocks_aggregate {
          aggregate {
            count
            sum {
              area
            }
          }
        }
      }
      transactions_balance_aggregate {
          aggregate {
            sum {
              number_of_heads
              total_weight
            }
          }
        }
    }

    transactions: batch_transactions(
      order_by: {event_date: desc},
      where: {batch_id: {_eq: $id}},
    ) {
      id
      event_date
      type
      category
      category_animal
      category_complementary
      genetic_pattern
      number_of_heads
      average_weight
      day_occupation
      info
      weighing_type
      interval
      total_weight
      batch_number_of_heads
      batch_average_weight
      batch_total_weight
      module_capacity
      instant_charge
      expected_performance
      expected_performance_interval
      real_performance
      arrobas_produced
      real_arrobas_produced
      available_dry_matter
      crude_protein
      fiberIn_neutral_detergent
      metabolizable_energy
      rest_time
      expected_consumption
      consumption_by_live_weight
      transferred_batch
      transferred_module
      supplement_mineral_consumption
      supplement_mineral_description
      supplement_mineral_pb
      supplement_mineral_ndt 
      supplement_feed_consumption
      supplement_feed_description
      supplement_feed_pb
      supplement_feed_ndt
      supplement_feed_fdn
      supplement_vol_consumption
      supplement_vol_description
      supplement_vol_ms
      supplement_vol_pb
      supplement_vol_em
      supplement_vol_fdn
      supplement_enabled
      animals
      last_weighing
      last_weighing_date
      current_module
      module_area
      paddocks_in_use
      average_real_performance
      dailys_since_beginning
      sum_entry
      sum_departure
      dailys_since_last_weighing
      average_movemented_weight
    }
  }
`;
const QUERY_BATCH_BALANCE =  gql`
  query batch_transactions_balance_per_category($id: uuid!) { 
    batch_transactions_balance_per_category(where: {batch_id: {_eq: $id}, number_of_heads: {_gt: "0"}}) {
      gmd
      genetic_pattern
      category_complementary
      category_animal
      batch_id
      average_weight
      number_of_heads
      total_weight
      id
    }
  }
`;

export default {

  components: {
    Confirmation: () => import('@/components/Confirmation.vue'),
    Register
  },

  props: ['id'],

  data() {
    return {
      loading: false,
      loadingFarms: false,
      loadingModules: false,
      valid: true,
      createModule: false,
      moduleName: '',
      eventDate: "",
      eventTime: "",
      desactivated: false,
      lastTransactionDate: '',
      categoryAlert: '',
      activeBatches: [],
      availableModules: [],
      modules: [],
      transferred_module_id: {},
      batch: {},

      results: [],
      transactions: [],
      transactionsBalance: [],
      transactionsExpanded: [],
      form: {},

      headers: [
        { text: 'Data', value: 'event_date' },
        { text: 'Movimentação', value: 'type' },
        { text: 'Detalhes', value: 'category' },
        { text: 'Qntd. mov.', value: 'batch_number_of_heads' },
        { text: 'Peso méd. mov.', value: 'average_movemented_weight' },
        { text: 'Saldo', value: 'number_of_heads' },
        { text: 'Peso méd. saldo', value: 'average_weight' },
        { text: '', value: 'data-table-expand' },
      ],

      headersAnimal: [
        { text: 'Categoria', value: 'animal' },
        { text: 'Quantidade', value: 'number_of_heads' },
        { text: 'Peso médio', value: 'average_weight' },
        { text: 'Desempenho Previsto', value: 'gmd'},
        { text: 'Peso total', value: 'total_weight' },
      ],

      movementTypes: movementDataType,
      movementTypeOptions: movementDataTypeOptions,
      movementCategories: movementCategoryDataType,
      movementCategoriesOptions: movementCategoryDataTypeOptions,
      geneticPatterns: geneticPatternsDataType,
      geneticPatternsOptions: geneticPatternsDataTypeOptions,
      categoriesAnimal: categoriesAnimalDataType,
      categoriesAnimalOptions: categoriesAnimalDataTypeOptions,
      categoriesComplementary: categoriesComplementaryDataType,
      categoriesComplementaryOptions: categoriesComplementaryDataTypeOptions,
      supplementTypes: supplementDataType,
      supplementTypesOptions: supplementDataTypeOptions,
      daysOccupation: daysOccupationDataType,
      daysOccupationOptions: daysOccupationDataTypeOptions,
      weighingTypes: weighingDataType,
      weighingTypeOptions: weighingDataTypeOptions,

      geolocation: {
        latitude: null,
        longitude: null,
      },

      validations: {
        event_date: [(v) => !!v || 'Data é obrigatório', (v) => (!!v && dayjs().isAfter(dayjs(v))) || 'Não é possivel utilizar data futura'],
        event_time: [(v) => !!v || 'Hora é obrigatório', (v) => (!!v && dayjs().isAfter(dayjs(this.eventDate + 'T' +v))) || 'Não é possivel utilizar hora futura', (v) => (!this.lastTransactionDate || dayjs(this.eventDate + 'T' +v).isAfter(dayjs(this.lastTransactionDate))) || 'Não é possivel utilizar data anterior a ultima movimentação.'],
        type: [(v) => !!v || 'Movimentação é obrigatório'],
        category: [(v) => (!!v || ['OCUPACAO', 'PESAGEM', 'SUPLEMENTACAO', 'MUDANÇA_DE_MODULO'].includes(this.form.type)) || 'Tipo é obrigatório'],
        genetic_pattern: [(v) => (!!v || !this.showAnimalCategories) || 'Padrão genético é obrigatório'],
        category_animal: [(v) => (!!v || !this.showAnimalCategories) || 'Categoria animal é obrigatório'],
        category_complementary: [(v) => (!!v || !this.showAnimalCategories || this.categoriesComplementaryFiltred.length === 0) || 'Categoria complementar é obrigatório'],
        number_of_heads: [(v) => (!!v || ['OCUPACAO', 'PESAGEM', 'SUPLEMENTACAO', 'MUDANÇA_DE_MODULO'].includes(this.form.type)) || 'Quantidade é obrigatório'],
        average_weight: [(v) => (!!v || ['OCUPACAO', 'SUPLEMENTACAO', 'MUDANÇA_DE_MODULO'].includes(this.form.type)) || 'Peso médio é obrigatório'],
        weighing_type: [(v) => (!!v || ['OCUPACAO', 'SUPLEMENTACAO', 'MUDANÇA_DE_MODULO', 'FECHAMENTO'].includes(this.form.type)) || 'Método de pesagem é obrigatório'],
        day_occupation: [(v) => (!!v || this.form.type !== 'OCUPACAO') || 'Ocupação é obrigatório'],
        transferred_batch: [(v) => (!!v || (this.form.category !== 'ENTRADA_OUTRO_LOTE' && this.form.category !== 'SAIDA_OUTRO_LOTE' && (!(this.form.category === 'SAIDA_DESMAME_LOTE')))) || 'Lote é obrigatório'],
        availableModules: [(v) => (!!v || this.form.type !== 'MUDANÇA_DE_MODULO') || 'Módulo é obrigatório'],
        
        supplement_mineral_consumption: [(v) => {
          if (v && this.batch.average_weight) {
            const consumeMS = this.form.supplement_mineral_consumption * this.params['MINERAL_MS_PERC']
            const percent = (consumeMS / this.batch.average_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_MINERAL_LIMIT'] || 'Qtd. de MS excedeu o limite'
          }
          return true
        }],

        supplement_feed_consumption: [(v) => {
          if (v && this.batch.average_weight) {
            const consumeMS = this.form.supplement_feed_consumption * this.params['FEED_MS_PERC']
            const percent = (consumeMS / this.batch.average_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_FEED_LIMIT'] || 'Qtd. de MS excedeu o limite'
          }
          return true
        }],

        supplement_vol_ms: [(v) => {
          if (v && this.batch.average_weight) {
            const consumeMS = this.form.supplement_vol_consumption * (v / 100)
            const percent = (consumeMS / this.batch.average_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_VOL_LIMIT'] || 'Qtd. de MS excedeu o limite'
          }
          return true
        }],
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['isManager', 'isConsultant']),

    farm_id () {
      return this.batch && this.batch.module && this.batch.module.farm ? this.batch.module.farm.id : '';
    },

    module_id() {
      return this.batch && this.batch.module ? this.batch.module.id : '';
    },

    maxDate() {
      return dayjs().format('YYYY-MM-DDTHH:mm');
    },

    lastTransaction() {
      return this.transactions.length > 0 ? this.transactions[0] : {};
    },

    transactionsTypeFiltred() {
      if (this.transactionsBalance.length === 0 ) {
        if (this.transactions.length === 0) {
          return this.movementTypeOptions.filter((item) => {
            return item.value === 'ENTRADA'
          })
        } else {
          return this.movementTypeOptions.filter((item) => {
            return (item.value === 'ENTRADA' || item.value === 'ENCERRAR_LOTE')
          })
        }
      } else return this.movementTypeOptions
    },

    categoriesFiltred () {
      if (this.form.type === 'ENTRADA_NASCIMENTO') {
        this.categoriesAnimalFiltred()
      }
      return this.movementCategoriesOptions.filter((item) => {
        if (item.type === this.form.type) {
          if (this.form.category_animal !== 'BEZERRO') {
            if (!(item.value.startsWith('SAIDA_DESMAME'))) {
              return item
            }
          } else {
            if (['SAIDA_DESMAME_LOTE', 'SAIDA_DESMAME_OUTRO', 'SAIDA_DESMAME_VENDA', 'SAIDA_MORTE', 'SAIDA_AJUSTE'].includes(item.value)) {
              return item
            } else if (item.value.startsWith('ENTRADA')) {
              return item
            }
          }
        }
      })
    },

    geneticPatternsFiltred () {
      if (this.allowOtherCategory()) return this.geneticPatternsOptions
      return this.geneticPatternsOptions.filter((item) => {
        return this.transactionsBalance.some((transaction) => {
          return transaction.genetic_pattern === item.value
        })
      })
    },

    categoriesAnimalFiltred () {
      if (this.form.category === 'ENTRADA_NASCIMENTO') return [{category: 'BEZERRO', text: 'Bezerro/a ao pé', value: 'BEZERRO'}]
      if (this.allowOtherCategory()) return this.categoriesAnimalOptions
      return this.categoriesAnimalOptions.filter((item) => {
        return this.transactionsBalance.some((transaction) => {
          return transaction.genetic_pattern === this.form.genetic_pattern && transaction.category_animal === item.value
        })
      })
    },

    categoriesComplementaryFiltred () {
      if (this.allowOtherCategory()) {
        return this.categoriesComplementaryOptions.filter((item) => {
          return item.category === this.form.category_animal
        })
      }
      let option = []
      this.categoriesComplementaryOptions.filter((item) => {
        if (this.form.category_animal === item.category && this.form.category_complementary === item.value) {
            if (this.form.type === 'MUDANCA_CATEGORIA') {
              option = item.changeCategory 
            } else {
              option = [item.value]
            }
          return
        }
       })
      if (option) {
        return this.categoriesComplementaryOptions.filter((item) => {
          if (item.changeCategory) {
            for (let index = 0 ; index < option.length; index++) {
              if ( item.value.includes(option[index])) {
                return item.value
              }
            }
          }
              
        })
      }
    },

    showAnimalCategories () {
      return (!this.batch.category_animal) ||(this.form.type === 'ENTRADA' && this.batch.category_animal === 'FEMEA_+24') ||
        (['SAIDA', 'PESAGEM'].includes(this.form.type) && this.transactionsBalance.length > 1) || (this.form.type === 'MUDANCA_CATEGORIA')
    },

    showCategoryChangeAlert () {
      if (this.form && this.form.type === 'MUDANCA_CATEGORIA') {
        if (this.form.category_animal === 'FEMEA_12') {
          this.categoryAlert = ' * A categoria animal progredirá para Fêmea 13 - 24'
          return true
        } else if (this.form.category_animal === 'MACHO_12') {
          this.categoryAlert = ' * A categoria animal progredirá para Macho 13 - 24'
          return true
        } else if (this.form.category_animal === 'MACHO_13-24') {
          this.categoryAlert = ' * A categoria animal progredirá para Macho +24'
          return true
        } else if (this.form.category_animal && this.form.category_animal !== 'MACHO_+24') {
          this.categoryAlert = ' * selecione a categoria para a progressão'
          return true
        }
      }
    },

    supplementConsumption () {
      if (this.batch.supplement_enabled || this.batch.supplement_mineral_consumption || this.batch.supplement_feed_consumption || this.batch.supplement_vol_consumption  ) {
        return toNumber(this.batch.supplement_mineral_consumption || 0) + toNumber(this.batch.supplement_feed_consumption || 0) + toNumber(this.batch.supplement_vol_consumption || 0)
      }
      return null
    }
  },

  async created() {
    this.loading = true
    await this.fetchParams()
    await this.fetch()
    await this.fetchFarms()
    await this.fetchBatches()
    await this.fetchActiveModules()
    await this.calculateGMD()
    await this.fetchBatchesBalance()
    this.resetForm()
    this.form.transferred_module = this.batch.module.id
    this.loading = false
  },

  methods: {
    async reFetch() {
    this.loading = true
    await this.fetchParams()
    await this.fetch()
    await this.fetchBatches()
    await this.fetchActiveModules()
    await this.calculateGMD()
    await this.fetchBatchesBalance()
    this.form.transferred_batch = ''
    this.createModule = false
    this.loading = false
    },

    getFieldText (item) {
      let name = `${item.text} ${ item.module_name === ''? '' : item.module_name?  '('+ item.module_name + ')':'(Novo lote)'}`
      return name
    },

    getCategoryText (item) {
      if (this.form.type === 'MUDANCA_CATEGORIA' && this.form.category_animal === 'FEMEA_13-24') {
        if (item.category === 'FEMEA_+24') {
          return item.text + ' (Fêmea 24+)'
        } else {
          return item.text
        }
      } else { 
        return item.text 
      }
    },

    getModuleText (item) {
      let module = ''
      for (let index in this.modules) {
        if(this.modules[index].id === item) {
          module = this.modules[index]
          break
        }
      }
      if (module) {
        return module.name
      }
      return item
    },

    checkModule () {
      if (this.form.transferred_batch) {
        for (let index in this.activeBatches) {
          if (this.activeBatches[index].value === this.form.transferred_batch) {
            if (!this.activeBatches[index].module_name) {
              this.moduleName = this.activeBatches[index].text
              this.createModule = true
            } else {
              this.createModule = false
            }
            break
          }
        }
      }
    },

    checkMovimentSelectItems (item) {
      if (item.value === 'ENCERRAR_LOTE') {
        if (this.batch.number_of_heads > 0) {
          return true
        }
      }
      if (item.value === 'REDUCAO_DE_MODULO') {
        return true
      }
      return false
    },

    checkTypeSelectItems (item) {
      if (item.value.startsWith('SAIDA_DESMAME')) {
        if (!(this.batch.main_activity && this.batch.main_activity === 'CRIA')) {
          return true
        }
      }
      return false
    },

    checkCategoryComplementarySelectItems (item) {
      if (item.value === 'FEMEA_+24_PARIDA_VAZIA' || item.value === 'FEMEA_+24_SOLTEIRA_PRENHA') {
          return true
        }
      return false
    },

    checkTransactionWeighingTypeName (item) {
      if (item) {
        if(item.startsWith('BALANCA_') || item.startsWith('ESTIMATIVA_') ) {
          return item.replaceAll('_',' ').toLowerCase()
        } else {
          return 'Calculado'
        }
      } else {
        return 'Calculado'
      }
    },

    checkIntervalLastWeighning (item) {
      if (item.last_weighing_date) {
        let period = dayjs(item.event_date)
        return Math.round(period.diff(dayjs(item.last_weighing_date), 'days', true))
      } else {
        return null
      }
    },

    checkIntervalSinceBeggining (item) {
      let period = dayjs(item.event_date)
      return Math.round(period.diff(dayjs(this.transactions[this.transactions.length -1].event_date), 'days', true))
    },

    showTranscationsCategory(item) {
      let category = item.genetic_pattern + '/'
      let spacedCategory
      if(item.category_complementary) {
        spacedCategory = item.category_complementary.replaceAll('_',' ')
      } else {
        spacedCategory = item.category_animal.replaceAll('_',' ')
      }
      category = category + spacedCategory
      return category
    },

    getTransferredBatchName (transferred_batch) {
        let batch
        if(transferred_batch) {
         batch = this.activeBatches.find(element => element.value === transferred_batch);
        }
        return batch ? batch.text : ''
     },

    async fetchBatches() {
      try {
        const result = await this.$apollo.query({
          query: gql`
          query Batches($farm_id: uuid_comparison_exp!, $_neq: uuid!) {
            batches(where: {status: {_eq: "ATIVO"}, module: {farm_id: $farm_id}, id: {_neq: $_neq}}) {
              id
              code
              number_of_heads
              module_id
              module {
                id
                name
              }
            }
          }
          `,
          variables: {
            farm_id: {"_eq": this.batch.module.farm.id},
            _neq: this.batch.id
          },
          fetchPolicy: 'no-cache',
        });
        this.activeBatches = result.data.batches.map(item => ({value: item.id, text: item.code, number_of_heads: item.number_of_heads, module_id: item.module_id, module_name: item.module.name}));
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBatchesBalance() {
      try {
        const resultBatchBalance = await this.$apollo.query({
          query: QUERY_BATCH_BALANCE,
          variables: { id: this.batch.id},
          fetchPolicy: 'no-cache',
        });
        this.transactionsBalance = resultBatchBalance.data.batch_transactions_balance_per_category
      } catch (e) {
        console.log(e)
      }
    },

    async fetchParams() {
      try {
        const res = await this.$apollo.query({
          query: gql`
            query Params {
              params {
                id
                info
                value
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        const resultParams = res.data
        // Parametros ajustados
        const configParams = resultParams.params.reduce((acc, item) => {
          // return acc[item.id] = item.value
          return {...acc, [item.id]: item.value}
        }, {})
        this.params = configParams
      } catch (e) {
        console.error(e);
      }
    },

    animalLabel (item) {
      if (item.category_complementary) {
        return `${ this.geneticPatterns[item.genetic_pattern] } / ${ this.categoriesAnimal[item.category_animal] } / ${ this.categoriesComplementary[item.category_complementary] }`
      }
      return `${ this.geneticPatterns[item.genetic_pattern] } / ${ this.categoriesAnimal[item.category_animal] }`
    },

    allowOtherCategory () {
      return this.form.type === 'ENTRADA' &&
        (this.transactionsBalance.length === 0 ||
          (this.batch.category_animal === 'FEMEA_+24' && this.transactionsBalance.length < 3))
    },

    save() {
      if (!this.$refs.form.validate()) return false;
      if (!this.id) this.insert();
      // else this.update();
    },

    remove(transaction) {
      Swal.fire({
        title: 'Deseja excluir a movimentação?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Sim, apague!',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          return this.removeTransaction(transaction)
        }
      })
    },

    async add() {
      let addTransaction = true
      this.form.event_date = this.eventDate + 'T' +this.eventTime
      if (this.form.type !== 'ENCERRAR_LOTE' && this.form.type !== 'TROCA_DE_MODULO' && this.form.type !== 'MUDANCA_CATEGORIA') {
        if (!this.$refs.form.validate()) return false;
        if (!this.batch.genetic_pattern) {
          this.batch.genetic_pattern = this.form.genetic_pattern
          this.batch.category_animal = this.form.category_animal
          this.batch.category_complementary = this.form.category_complementary? this.form.category_complementary : null
          this.batch.day_occupation = this.form.day_occupation
          this.updateBatchCategory()
        }
      }
      if (this.form.type === 'SAIDA' && this.form.category === 'SAIDA_OUTRO_LOTE') {
        try {
        const result = await this.$apollo.query({
          query: QUERY_BATCH,
          variables: {
            id: this.form.transferred_batch
          },
          fetchPolicy: 'no-cache',  
        });
        if (result && result.data && result.data.batch) {
          const otherBatch = result.data.batch;
          if (!(otherBatch.main_activity === 'RECRIA_E_ENGORDA' && (otherBatch.genetic_pattern === this.form.genetic_pattern) 
          && (otherBatch.category_animal === this.form.category_animal) && (otherBatch.category_complementary === this.form.category_complementary)))
          {
            if (otherBatch.category_animal === 'MACHO_12') {
              otherBatch.category_animal = 'Macho até 12m'
            } else if (otherBatch.category_animal === 'FEMEA_12') {
              otherBatch.category_animal = 'Fêmea até 12m'
            } else if (otherBatch.category_animal === 'BEZERRO') {
              otherBatch.category_animal = 'Bezerro/a ao pé'
            } 
            let title = 'a categoria dos animais transferidos irá mudar para "' + (otherBatch.category_complementary? otherBatch.category_complementary : otherBatch.category_animal).replaceAll('_',' ') + '"'
            await Swal.fire({
              title: title,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Sim, mude!',
              cancelButtonText: 'Cancelar',
            }).then((result) => {
              if (!result.value) {
                addTransaction = false
              }
            })
          }
        } else {
          addTransaction = false
          Swal.fire({
           icon: 'error',
           text: 'Erro ao resgatar informações do lote de destino!',
           showConfirmButton: false,
           timer: 1500,
         });
        }
        } catch (e) {
          console.log(e)
        }
      }
      if (this.form.type === 'SAIDA' && this.form.number_of_heads > this.batch.number_of_heads) return false;                   //VERIFICA NUM. GADOS
      else if ((this.form.category === 'SAIDA_OUTRO_LOTE' || (this.form.category === 'SAIDA_DESMAME_LOTE' && this.form.transferred_batch !== 'Indo para outro pasto não cadastrado ( )')) && addTransaction && !(await this.insertTransactionOnTransferredBatch())) return false;  //VERIFICA TRANSFERENCIA P/ OUTRO LOTE

      else if (this.form.category === 'ENTRADA_OUTRO_LOTE') {
        let transferred_batch = this.activeBatches.find(batch => batch.value === this.form.transferred_batch);                                            // BUSCA NUM. GADOS NO OUTRO LOTE
        if (Number(this.form.number_of_heads) > transferred_batch.number_of_heads || !(await this.insertTransactionOnTransferredBatch())) return false;  // VERIFICA NUM. GADOS & VERIFICA TRANSF. P/ OUTRO LOTE
      }
      else if (this.form.type === 'MUDANÇA_DE_MODULO') {
        this.form.transferred_module = this.batch.module.id
        await this.updateBatch(undefined)
        this.form.category = null
      }
      else if (this.form.type === 'TROCA_DE_MODULO') {
        let moduleID
        for (let index in this.activeBatches) {
          if (this.activeBatches[index].value === this.form.transferred_batch) {
            moduleID = this.activeBatches[index].module_id
            break
          }
        }
        this.form.transferred_module = moduleID
        this.transferred_module_id = moduleID
        await this.updateBatch(undefined)
        this.transferred_module_id = this.batch.module.id
        this.form.transferred_module = this.batch.module.id
        await this.updateBatch(this.form.transferred_batch)
        this.form.category = null
        this.form.transferred_module = moduleID
        const batchID = this.form.transferred_batch
        this.form.batch_id = this.form.transferred_batch
        this.form.transferred_batch = this.batch.id
        await this.insertTransactionOnTransferredModuleBatch()
        this.form.transferred_module = this.batch.module.id
        this.form.batch_id = this.batch.id
        this.form.transferred_batch = batchID
      }
      if (this.transactionsBalance.length > 0) {
        if (!this.form.category_animal) {
          this.form.genetic_pattern = this.transactionsBalance[0].genetic_pattern
          this.form.category_animal = this.transactionsBalance[0].category_animal
          this.form.category_complementary = this.transactionsBalance[0].category_complementary
        }
        if (!this.form.average_weight) {
          this.form.average_weight = this.transactionsBalance[0].average_weight
        }
        if (!this.form.number_of_heads) {
          this.form.number_of_heads = this.transactionsBalance[0].number_of_heads
        }
      } else {
        if (!this.form.category_animal) {
          this.form.category_animal = this.batch.category_animal
          this.form.category_complementary = this.batch.category_complementary
        }
        if (!this.form.average_weight) {
          this.form.average_weight = this.batch.average_weight
        }
        if (!this.form.number_of_heads) {
          this.form.number_of_heads = this.batch.number_of_heads
        }
      }
      if (addTransaction) {
        this.loading = true
        await this.insertTransaction();
        await this.fetch();
        await this.fetchBatches();
        await this.fetchActiveModules();
        await this.fetchBatchesBalance();
        await this.calculateGMD();
        this.loading = false
      }
    },

    getFormSave(formParam) {
      const form = cloneDeep(formParam)
      form.customer_id = this.batch.customer.id
      form.batch_id = this.batch.id
      form.number_of_heads = toInt(form.number_of_heads)
      form.average_weight = toNumber(form.average_weight)
      form.day_occupation = toNumber(form.day_occupation)
      form.supplement_mineral_consumption = toNumber(form.supplement_mineral_consumption) 
      form.supplement_mineral_pb = toNumber(form.supplement_mineral_pb)
      form.supplement_mineral_ndt = toNumber(form.supplement_mineral_ndt)
      form.supplement_feed_consumption = toNumber(form.supplement_feed_consumption)
      form.supplement_feed_pb = toNumber(form.supplement_feed_pb)
      form.supplement_feed_ndt = toNumber(form.supplement_feed_ndt)
      form.supplement_feed_fdn = toNumber(form.supplement_feed_fdn)
      form.supplement_vol_consumption = toNumber(form.supplement_vol_consumption)
      form.supplement_vol_ms = toNumber(form.supplement_vol_ms)
      form.supplement_vol_pb = toNumber(form.supplement_vol_pb)
      form.supplement_vol_em = toNumber(form.supplement_vol_em )
      form.supplement_vol_fdn = toNumber(form.supplement_vol_fdn)
      return form
    },

    async updateBatch(batchID) {
      this.loading = true;
      try {
        let form = {};
        form.module_id = this.transferred_module_id;
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $batch: batches_set_input!) {
              update_batches_by_pk(pk_columns: { id: $id }, _set: $batch) {
                id
              }
            }
          `,
          variables: {
            id: batchID? batchID : this.id,
            batch: form,
          },
        });
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async updateBatchCategory() {
      this.loading = true;
      try {
        let form = {}
        form.genetic_pattern = this.batch.genetic_pattern
        form.category_animal = this.batch.category_animal
        form.category_complementary = this.batch.category_complementary
        form.day_occupation = this.batch.day_occupation
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $batch: batches_set_input!) {
              update_batches_by_pk(pk_columns: { id: $id }, _set: $batch) {
                id
              }
            }
          `,
          variables: {
            id: this.id,
            batch: form,
          },
        });
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async insertTransactionOnTransferredBatch() {
      this.loading = true;
      try {
        const form = this.getFormSave(this.form)
        if(form.category === 'ENTRADA_OUTRO_LOTE') {
          form.category = 'SAIDA_OUTRO_LOTE'
          form.type = 'SAIDA'
          form.batch_id = form.transferred_batch
          form.transferred_batch = this.batch.id
        } else if (form.category === 'SAIDA_OUTRO_LOTE' || this.form.category === 'SAIDA_DESMAME_LOTE' ) {
          form.category = 'ENTRADA_OUTRO_LOTE'
          form.type = 'ENTRADA'
          form.batch_id = form.transferred_batch
          form.transferred_batch = this.batch.id
        } else if (form.type === 'TROCA_DE_MODULO') {
          form.type = 'MUDANÇA_MODULO'
        }
         await this.$apollo.mutate({
           mutation: gql`
             mutation ($transaction: batchTransactionInput!) {
               batch_transactions_add(data: $transaction) {
                 success
               }
             }
           `,
           variables: {
             transaction: form,
           },
         });
         Swal.fire({
           icon: 'success',
           text: 'Registro criado com sucesso!',
           showConfirmButton: false,
           timer: 1500,
         });
         setTimeout(async () => {
           await this.fetch()
           this.resetForm()
         }, 200)
       } catch (e) {
         Swal.fire({
           icon: 'error',
           text: e.message,
           showConfirmButton: false,
           timer: 2500,
         });
         this.loading = false;
         return false;
       }
       this.loading = false;
       return true;
    },

    async insertTransactionOnTransferredModuleBatch() {
      this.loading = true;
      try {
        const form = this.form
        if (form.type === 'TROCA_DE_MODULO') {
          form.customer_id = this.batch.customer.id
          form.type = 'TROCA_DE_MODULO'
        }
         await this.$apollo.mutate({
           mutation: gql`
             mutation ($transaction: batchTransactionInput!) {
               batch_transactions_add(data: $transaction) {
                 success
               }
             }
           `,
           variables: {
             transaction: form,
           },
         });
         Swal.fire({
           icon: 'success',
           text: 'Registro criado com sucesso!',
           showConfirmButton: false,
           timer: 1500,
         });
         setTimeout(async () => {
           await this.fetch()
           this.resetForm()
         }, 200)
       } catch (e) {
         Swal.fire({
           icon: 'error',
           text: e.message,
           showConfirmButton: false,
           timer: 2500,
         });
         this.loading = false;
         return false;
       }
       this.loading = false;
       return true;
    },

    async insertTransaction() {
      try {
        const form = this.getFormSave(this.form)
        if (form.transferred_batch === 'Indo para outro pasto não cadastrado' ) {
          form.transferred_batch = undefined
        }
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($transaction: batchTransactionInput!) {
              batch_transactions_add(data: $transaction) {
                success
              }
            }
          `,
          variables: {
            transaction: form
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(async () => {
          await this.fetch()
          this.resetForm()
        }, 200)
      } catch (e) {
        Swal.fire({
          icon: 'error',
          text: e.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },

    removeTransaction(transaction) {
      this.loading = true;
      const batchID = this.batch.id
      const data = {
            id: transaction.id,
            batch_id: batchID,
          }
      return this.$apollo.mutate({
        mutation: gql`
          mutation ($data: removeInput!) {
            batch_transactions_remove(data: $data) {
              success
            }
          }
        `,
        variables: {
          data: data
        },
      }).then(({ data }) => {
        if (data.batch_transactions_remove.success) {
          Swal.fire({
            icon: 'success',
            text: 'Registro removido com sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(async () => {
            await this.fetch()
            this.resetForm()
          }, 200)
        }
      }).catch(e => {
        Swal.fire({
          icon: 'error',
          text: e.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }).finally(async () => {
        await this.fetch()
        await this.fetchBatches()
        await this.fetchActiveModules()
        await this.fetchBatchesBalance()
        await this.calculateGMD()
        this.loading = false;
      })
    },

    checkCategoryChange(item) {
      if (item.type === 'MUDANCA_CATEGORIA') {
        let string = ''
        const animals = JSON.parse(item.animals)
        for (let i = 0; i < animals.length; i++) {
          if (item.batch_category_changed === item.animals[i].id) {
            string = animals[i].category_complementary? animals[i].category_complementary : animals[i].category_animal
          }
        }
        string = string + " > " + (item.category_complementary? item.category_complementary : item.category_animal)
        return  string.replaceAll('_',' ')
      } else {
        return false
      }
    },

    async calculateGMD() {
      if (this.batch.number_of_heads > 0) {
        const data = {
          batch_id: this.batch.id,
          day_occupation: this.batch.day_occupation,
          
        }
        if (this.batch.supplement_mineral_consumption > 0 || this.batch.supplement_feed_consumption > 0 
          || this.batch.supplement_vol_consumption > 0 ) {
            data.supplement_enabled = true
        }
        try {
          const res = await this.$apollo.mutate({
            mutation: gql`
              mutation ($transaction: batchInput!) {
                batch_transactions_calculate_gmd(data: $transaction) {
                  success
                  message
                  calc
                }
              }
            `,
            variables: {
              transaction: data
            },
          });
          if (res.data && res.data.batch_transactions_calculate_gmd && res.data.batch_transactions_calculate_gmd.calc.results) {
            this.batch.expected_performance = res.data.batch_transactions_calculate_gmd.calc.results.expectedPerformance
          } else if (res.data && res.data.batch_transactions_calculate_gmd && res.data.batch_transactions_calculate_gmd.calc) {
            if (res.data.batch_transactions_calculate_gmd.calc.length > 0) {
            this.batch.expected_performance = res.data.batch_transactions_calculate_gmd.calc[0].expectedPerformance
            }
          } 
        } catch(e) {
          console.log(e)
        }
      }
    },

    async recalculateTransaction(item) {
      Swal.fire({
          icon: 'warning',
          text: 'Quer mesmo recalcular os dados de desempenho desta movimentação?',
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Sim',
          showDenyButton: true,
          denyButtonText: 'Não',
        }).then(async (result) => {
          if (result.isConfirmed) {
            
          let data = {}
          data.id = item.id
          data.type = item.type
          data.category = item.category
          data.genetic_pattern = item.genetic_pattern
          data.category_animal  = item.category_animal
          data.category_complementary = item.category_complementary
          data.average_weight = item.average_weight
          data.expected_performance = item.expected_performance
          if (data.type === 'ENTRADA'|| data.type === 'SAIDA') {
            data.number_of_heads = item.number_of_heads
          }else {
            data.number_of_heads = item.batch_number_of_heads
          }
          data.event_date = item.event_date
          data.day_occupation = item.day_occupation
          if (item.supplement_mineral_consumption || item.supplement_feed_consumption || item.supplement_vol_consumption)
          {
            data.supplement_enabled = true
          } else {
            data.supplement_enabled = false
          }
          data.supplement_mineral_consumption = item.supplement_mineral_consumption
          data.supplement_mineral_pb = item.supplement_mineral_pb
          data.supplement_mineral_ndt = item.supplement_mineral_ndt
          data.supplement_feed_consumption = item.supplement_feed_consumption
          data.supplement_feed_pb = item.supplement_feed_pb
          data.supplement_feed_ndt = item.supplement_feed_ndt
          data.supplement_feed_fdn = item.supplement_feed_fdn
          data.supplement_vol_consumption = item.supplement_vol_consumption
          data.supplement_vol_ms = item.supplement_vol_ms
          data.supplement_vol_pb = item.supplement_vol_pb
          data.supplement_vol_em = item.supplement_vol_em
          data.supplement_vol_fdn = item.supplement_vol_fdn
          data.weighing_type = item.weighing_type
          data.farm_id = this.batch.module.farm.id
          data.module_id = this.batch.module.id
          data.batch_id = this.batch.id
          data.animals = item.animals
          try {
            await this.$apollo.mutate({
              mutation: gql`
                mutation ($transaction: batchTransactionRecalculateInput!) {
                  batch_transactions_recalculate(data: $transaction) {
                    success
                  }
                }
              `,
              variables: {
                transaction: data
              },
            });
            setTimeout(async () => {
              await this.fetch()
              this.resetForm()
            }, 200)
          } catch (e) {
            Swal.fire({
              icon: 'error',
              text: e.message,
              showConfirmButton: false,
              timer: 2500,
            });
          }
          /* await this.fetch();
          await this.fetchBatches();
          await this.fetchActiveModules();
          await this.fetchBatchesBalance(); */
          Swal.fire({
            icon: 'success',
            text: 'Dados de desempenho recalculados com sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset()
      // tempo para resetar o form
      setTimeout(() => {
        this.form.event_date = null
        this.form.genetic_pattern = this.batch.genetic_pattern
        if (this.batch.main_activity !== 'CRIA') {
          this.form.category_animal = this.batch.category_animal
          this.form.category_complementary = this.batch.category_complementary
          this.form.number_of_heads = this.batch.number_of_heads
        }
        // this.form.average_weight = this.batch.average_weight
        this.form.supplement_mineral_consumption = this.batch.supplement_mineral_consumption
        this.form.supplement_mineral_description = this.batch.supplement_mineral_description
        this.form.supplement_mineral_pb = this.batch.supplement_mineral_pb
        this.form.supplement_mineral_ndt = this.batch.supplement_mineral_ndt
        this.form.supplement_feed_consumption = this.batch.supplement_feed_consumption
        this.form.supplement_feed_description = this.batch.supplement_feed_description
        this.form.supplement_feed_pb = this.batch.supplement_feed_pb
        this.form.supplement_feed_ndt = this.batch.supplement_feed_ndt
        this.form.supplement_feed_fdn = this.batch.supplement_feed_fdn
        this.form.supplement_vol_consumption = this.batch.supplement_vol_consumption
        this.form.supplement_vol_description = this.batch.supplement_vol_description
        this.form.supplement_vol_ms = this.batch.supplement_vol_ms
        this.form.supplement_vol_pb = this.batch.supplement_vol_pb
        this.form.supplement_vol_em = this.batch.supplement_vol_em 
        this.form.supplement_vol_fdn = this.batch.supplement_vol_fdn
      }, 300)
    },

    async fetch() {
      try {
        const result = await this.$apollo.query({
          query: QUERY_BATCH,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',  
        });
        const batch = result.data.batch
        const transactions = result.data.transactions
        delete batch.__typename
        this.batch = cloneDeep(batch)
        this.transactions = cloneDeep(transactions)
        if (transactions && transactions[0] && transactions[0].event_date) {
          this.lastTransactionDate= dayjs(transactions[0].event_date)
        }
      } catch (e) {
        console.error(e);
      }
    },

    async fetchFarms() {
      this.loadingFarms = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query farms {
              farms(
                order_by: [{ name: asc }]
              ) {
                id
                name
                desactivated
                customer{
                  id
                  name
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        this.loadingFarms = false
        this.farms = result.data.farms.map(item => ({value: item.id, text: item.name, ...item}));
      } catch (e) {
        console.error(e);
      }
      this.loadingFarms = false
    },

    async fetchActiveModules() {
      this.loading = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query AvailableModules($_eq: uuid = "", $_nin: [uuid!] = "") {
              modules(where: {farm_id: {_eq: $_eq}, _and: {id: {_nin: $_nin}}}) {
                id
                name
                desactivated
              }
            }
          `,
          variables: {
            _eq: this.batch.module.farm.id,
            _nin: this.activeBatches.map(item => { return item.module_id })
          },
          fetchPolicy: 'no-cache',
        });
        const module = result.data.modules.filter(item => item.id == this.batch.module.id)
        const moduleInclude = this.activeBatches.map(item => { return {id: item.module_id, name: item.module_name} })
        this.desactivated = module[0].desactivated
        this.availableModules = result.data.modules.filter(element => (element.id !==  this.batch.module.id && !element.desactivated));
        this.availableModules = this.availableModules.map(item => ({value: item.id, text: item.name}));
        this.activeBatches = [...this.activeBatches, ...this.availableModules]
        this.modules = result.data.modules
        this.modules = [...this.modules, ...moduleInclude]
      } catch (e) {
        console.error(e);
      }
      this.loading = false
    },

    checkUser() {
      if (!this.isManager) {
        if (this.form && this.farm_id) {
          let farm = {}
          if (this.farms) {
            for (let index = 0; index < this.farms.length; index++ ) {
              if (this.farms[index].id === this.farm_id) {
                farm = this.farms[index]
                break
              }
            }
          }
          if (farm.desactivated) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },

    farmFilter (item, queryText) {
      const textOne = item?.name?.toLowerCase()
      const textTwo = item?.customer?.name?.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },

    async populateModules() {
      this.form.customer_id = this.farms.find(item => item.value === this.form.farm_id).customer.id
      this.form.module_id = null
      this.modules = (await this.fetchModules()).map((item) => ({
        value: item.id,
        text: item.name,
      }));
    },

    changeCategoryAnimal() {
      for (let index in this.transactionsBalance) {
        if ( this.transactionsBalance[index].genetic_pattern === this.form.genetic_pattern &&
         this.transactionsBalance[index].category_animal === this.form.category_animal) {
          this.form.category_complementary = this.transactionsBalance[index].category_complementary
          return
        }
      }
      this.form.category_complementary = null
    },

    async fetchModules() {
      this.loadingModules = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query modules ($farmId: uuid!) {
              modules(
                where: {
                  farm_id: { _eq: $farmId }
                },
                order_by: [{ name: asc }]
              ) {
                id
                name
              }
            }
          `,
          variables: {
            farmId: this.form.farm_id
          },
          fetchPolicy: 'no-cache',
        });
        this.loadingModules = false
        return result.data.modules;
      } catch (e) {
        console.error(e);
      }
    },

    async exportBatch() {
      this.results = [[
        'Cliente', 
        'Fazenda',
        'Padrão genético',
        'Categoria Animal',
        'Módulo',
        'Área total (ha)',
        'Ocupação (dias/piquete)', 
        'Data',
        'Movimentação',
        'Detalhes',
        'Qtd. (cab.)',
        'Saldo (cab.)',
        'Período (dias)', 
        'Tamanho do lote (cab)', 
        'Peso médio do lote (kg)',
        'Peso total do lote (kg)', 
        'Peso total movimentado (kg)', 
        'Dias de ocupação (hrs/piquete)', 
        'Tempo de descanso (dias)', 
        'Matéria seca disponível (kg/piquete)',
        'Proteína bruta (%)',
        'Fibra em detergente neutro (%)',
        'Energia metabolizável (Mcal/kg)',
        'Consumo esperado (kg MS/cab/dia)',
        'Consumo / Peso vivo (%)',
        'Lotação (UA/ha)',
        'Carga instantânea (UA/ha)', 
        'GMD estimado (g/cab/dia)',
        'GMD real (última pesagem) (g/cab/dia)',
        'Produtividade real (@/ha/mês)',
        'Produtividade estimada (@/ha/mês)'
      ]]
      const fixed = [
        this.batch.customer.name,
        this.batch.module.farm.name,
        this.geneticPatterns[this.batch.genetic_pattern],
        this.categoriesAnimal[this.batch.category_animal],
        this.batch.module.name,
        this.batch.module.paddocks_aggregate.aggregate.sum.area,
        this.batch.day_occupation
      ]

      for (let transaction of this.transactions) {
            this.results.push(fixed.concat([ 
              moment(transaction.event_date).format('DD/MM/YYY'),
              this.movementTypes[transaction.type],
              // DEATALHES 
              transaction.type === 'SUPLEMENTACAO' ? 
              transaction.supplement_mineral_consumption + 
              transaction.supplement_feed_consumption + 
              transaction.supplement_vol_consumption : 0 || 
              this.movementCategories[transaction.category] || 
              this.weighingTypes[transaction.weighing_type] || 
              this.daysOccupation[transaction.day_occupation],
              // --------------
              transaction.type === 'ENTRADA' || transaction.type === 'SAIDA' || transaction.type === 'MUDANÇA_DE_MODULO' ? transaction.number_of_heads : 0 ,
              transaction.batch_number_of_heads,
              transaction.interval,
              transaction.batch_number_of_heads,
              transaction.batch_average_weight,
              transaction.batch_total_weight,
              transaction.total_weight,
              transaction.day_occupation,
              transaction.rest_time,
              transaction.available_dry_matter,
              transaction.crude_protein*100,
              transaction.fiberIn_neutral_detergent,
              transaction.metabolizable_energy,
              transaction.expected_consumption,
              transaction.consumption_by_live_weight,
              transaction.module_capacity,
              transaction.instant_charge,
              transaction.expected_performance,
              transaction.real_performance,
              transaction.real_arrobas_produced,
              transaction.arrobas_produced,
            ]))
      }
      this.exportToCsv()
    },

    exportToCsv() {
      var CsvString = "";
      this.results.forEach(function(RowItem) {
        RowItem.forEach(function(ColItem) {
          if(!ColItem) ColItem = ''
          CsvString += '\ufeff' + ColItem + ';'
        });
        CsvString += "\r\n";
      });
      CsvString = "data:application/csv," + encodeURIComponent(CsvString);
      
      var x = document.createElement("A");
      x.setAttribute("href", CsvString );
      x.setAttribute("download","batch-report.csv");
      document.body.appendChild(x);
      x.click();
    }

  },
};
</script>

<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="4">
          <div v-if="batch.status === 'ENCERRADO'">
            <h2 class="mb-0 text--secondary">Lote encerrado</h2>
            <p class="text--secondary"><i>Não será possivel realizar movimentações</i></p>
            <v-alert
              border="left"
              colored-border
              color="red darken-4"
              elevation="1"
            >
              <span>
                Somente é possível movimentar lotes que estão ativos. Favor verificar a situação no cadastro do lote.
              </span>
            </v-alert>
          </div>
          <div v-else-if="desactivated">
            <h2 class="mb-0 text--secondary">Lote de módulo desativado</h2>
            <p class="text--secondary"><i>Não será possivel realizar movimentações</i></p>
            <v-alert
              border="left"
              colored-border
              color="red darken-4"
              elevation="1"
            >
              <span>
                Somente é possível movimentar lotes de módulos ativos. Favor verificar a situação no cadastro do módulo.
              </span>
            </v-alert>
          </div>
          <div v-else>
            <h2 class="mb-0 text--secondary">Movimentações</h2>
            <p class="text--secondary"><i>Informe os dados abaixo</i></p>
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="add">
              <v-card>
                <v-card-title class="subtitle-2">Nova movimentação</v-card-title>
                <v-card-text>
                  <div>
                    <v-container>
                      <v-row>
                        <v-col class="pb-0">
                          <v-text-field
                            v-model="eventDate"
                            :rules="validations.event_date"
                            label="Data do evento"
                            type="date"
                            dense
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                        <v-col class="pb-0">
                          <v-text-field
                            v-model="eventTime"
                            label="Hora do evento"
                            :rules="validations.event_time"
                            type="time"
                            dense
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pb-0">
                          <v-autocomplete
                            v-model="form.type"
                            :items="transactionsTypeFiltred"
                            :rules="validations.type"
                            :item-disabled="checkMovimentSelectItems"
                            label="Movimentação"
                            dense 
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                      </v-row>
                      <v-row v-show="form.type && categoriesFiltred.length && (form.category_animal || batch.main_activity !== 'CRIA' || transactionsBalance.length === 1 )">
                        <v-col class="pb-0">
                          <v-autocomplete
                            v-model="form.category"
                            :items="categoriesFiltred"
                            :rules="validations.category"
                            :item-disabled="checkTypeSelectItems"
                            label="Tipo"
                            dense
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                      </v-row>
                      <v-row v-show="form.type && (form.type === 'MUDANÇA_DE_MODULO') && form.type !== 'ENCERRAR_LOTE'">
                        <v-col class="pb-0">
                          <v-autocomplete
                            v-model="transferred_module_id"
                            :items="availableModules"
                            :rules="validations.availableModules"
                            label="Módulo"
                            dense
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                      </v-row>
                      <v-row v-show="form.type && (form.type === 'ENTRADA' && form.category === 'ENTRADA_OUTRO_LOTE')
                      || ((form.type === 'SAIDA' && (form.category === 'SAIDA_OUTRO_LOTE'|| form.category === 'SAIDA_DESMAME_LOTE')) || form.type === 'TROCA_DE_MODULO')">
                        <v-col class="pb-0">
                          <v-autocomplete
                            v-model="form.transferred_batch"
                            :items="activeBatches"
                            :item-text="getFieldText"
                            :rules="validations.transferred_batch"
                            label="Lote"
                            dense
                            outlined
                            persistent-placeholder
                            @change="checkModule"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="createModule">
                        <v-col>
                          <Register :farm="farm_id" :category="form.category_animal" :genetic="form.genetic_pattern" :complementary="form.category_complementary" 
                          :status="createModule" :name="moduleName" @close-dialog="reFetch">
                          </Register>
                        </v-col>
                      </v-row>
                      <v-row v-show="form.type && (form.type === 'OCUPACAO'|| form.type === 'MUDANÇA_DE_MODULO' || !batch.day_occupation)">
                        <v-col class="pb-0">
                          <v-autocomplete
                            v-model="form.day_occupation"
                            :items="daysOccupationOptions"
                            :rules="validations.day_occupation"
                            label="Ocupação"
                            dense
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                      </v-row>
            <!-- SUPLEMENTAÇÃO -->
                      <v-row v-show="form.type && form.type ==='SUPLEMENTACAO'">
                        <v-col class="pb-0 pt-0 align-items-center">
                          <v-expand-transition>
                            <!-- v-show="batch.supplement_enabled" -->
                            <div class="pb-6">
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    Mineral proteinado e/ou energético
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-row>
                                      <v-col sm='6'>
                                        <v-text-field
                                          v-model="form.supplement_mineral_consumption"
                                          :rules="validations.supplement_mineral_consumption"
                                          label="Consumo"
                                          suffix="kg"
                                          hint='(MN/cab/dia)'
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-col sm='6'>
                                        <v-text-field
                                          v-model="form.supplement_mineral_pb"
                                          label="Teor de PB"
                                          suffix="%"
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-col sm='12'>
                                        <v-text-field
                                          v-model="form.supplement_mineral_ndt"
                                          label="Teor de NDT"
                                          hint='(será convertido em EM)'
                                          suffix="%"
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-col sm='12'>
                                        <v-text-field
                                          v-model="form.supplement_mineral_description"
                                          label="Descrição"
                                          type="text"
                                          :counter="30"
                                          :maxlength="30"
                                          hint='descrição do produto (até 30 caracteres)'
                                          class="text-field-left"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                          single-line
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    Ração concentrada
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-row>
                                      <v-col sm='6'>
                                        <v-text-field
                                          v-model="form.supplement_feed_consumption"
                                          :rules="validations.supplement_feed_consumption"
                                          label="Consumo"
                                          suffix="kg"
                                          hint='(MN/cab/dia)'
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-col sm='6'>
                                        <v-text-field
                                          v-model="form.supplement_feed_pb"
                                          label="Teor de PB"
                                          suffix="%"
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-col sm='6'>
                                        <v-text-field
                                          v-model="form.supplement_feed_ndt"
                                          label="Teor de NDT"
                                          hint='(será conv. em EM)'
                                          suffix="%"
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-col sm='6'>
                                        <v-text-field
                                          v-model="form.supplement_feed_fdn"
                                          label="Teor de FDN"
                                          suffix="%"
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-text-field
                                          v-model="form.supplement_feed_description"
                                          label="Descrição"
                                          type="text"
                                          :counter="30"
                                          :maxlength="30"
                                          hint='descrição do produto (até 30 caracteres)'
                                          class="text-field-left"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                          single-line
                                        />
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    Volumoso
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-row>
                                      <v-col sm='6'>
                                        <v-text-field
                                          v-model="form.supplement_vol_consumption"
                                          label="Consumo"
                                          suffix="kg"
                                          hint='(MN/cab/dia)'
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-col sm='6'>
                                        <v-text-field
                                          v-model="form.supplement_vol_ms"
                                          :rules="validations.supplement_vol_ms"
                                          label="Teor de MS"
                                          suffix="%"
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-col sm='6'>
                                        <v-text-field
                                          v-model="form.supplement_vol_pb"
                                          label="Teor de PB"
                                          suffix="%"
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-col sm='6'>
                                        <v-text-field
                                          v-model="form.supplement_vol_em"
                                          label="Teor de EM"
                                          suffix="Mcal/kg"
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-col sm='12'>
                                        <v-text-field
                                          v-model="form.supplement_vol_fdn"
                                          label="Teor de FDN"
                                          suffix="%"
                                          type="number"
                                          class="text-field-right"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                        />
                                      </v-col>
                                      <v-text-field
                                          v-model="form.supplement_vol_description"
                                          label="Descrição"
                                          type="text"
                                          :counter="30"
                                          :maxlength="30"
                                          hint='descrição do produto (até 30 caracteres)'
                                          class="text-field-left"
                                          dense
                                          persistent-placeholder
                                          persistent-hint
                                          outlined
                                          single-line
                                        />
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </div>
                          </v-expand-transition>
                        </v-col>
                      </v-row>
            <!-- FIM -- SUPLEMENTAÇÃO -->
                      <v-row v-show="showAnimalCategories">
                        <v-col md="6" sm="12">
                          <v-autocomplete
                            v-model="form.genetic_pattern"
                            :items="geneticPatternsFiltred"
                            :rules="validations.genetic_pattern"
                            label="Padrão genético"
                            dense
                            outlined
                            persistent-placeholder
                            @change="changeCategoryAnimal"
                          />
                        </v-col>
                        <v-col>
                          <v-autocomplete
                            v-model="form.category_animal"
                            :items="categoriesAnimalFiltred"
                            :rules="validations.category_animal"
                            label="Categoria animal"
                            dense
                            outlined
                            persistent-placeholder
                            @change="changeCategoryAnimal"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <span v-show="showCategoryChangeAlert" style="font-size: 110%;">{{ categoryAlert }}</span>
                      </v-row>
                      <v-row v-show="showAnimalCategories && categoriesComplementaryFiltred.length">
                        <v-col>
                          <v-autocomplete
                            v-model="form.category_complementary"
                            :items="categoriesComplementaryFiltred"
                            :item-text="getCategoryText"
                            :rules="validations.category_complementary"
                            :item-disabled="checkCategoryComplementarySelectItems"
                            label="Categoria complementar"
                            dense
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                      </v-row>
                      <v-row v-show="form.type && (form.type !== 'OCUPACAO' && form.type !== 'MUDANÇA_DE_MODULO') && form.type !== 'SUPLEMENTACAO' && form.type !== 'ENCERRAR_LOTE' && form.type !== 'TROCA_DE_MODULO' && form.type !== 'MUDANCA_CATEGORIA'">
                        <v-col v-show="form.type !== 'PESAGEM'" class="pb-0">
                          <v-text-field
                            v-model="form.number_of_heads"
                            :rules="validations.number_of_heads"
                            label="Quantidade cab."
                            type="number"
                            class="text-field-right"
                            dense
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                        <v-col class="pb-0">
                          <v-text-field
                            v-model="form.average_weight"
                            :rules="validations.average_weight"
                            label="Peso médio"
                            suffix="kg"
                            type="number"
                            class="text-field-right"
                            dense
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                      </v-row>
                      <v-row v-show="form.type && (form.type !== 'OCUPACAO' && form.type !== 'SUPLEMENTACAO' && form.type !== 'MUDANÇA_DE_MODULO' && form.type !== 'ENCERRAR_LOTE' && form.type !== 'FECHAMENTO' && form.type !== 'TROCA_DE_MODULO' && form.type !== 'MUDANCA_CATEGORIA')">
                        <v-col class="pb-0">
                          <v-autocomplete
                            v-model="form.weighing_type"
                            :items="weighingTypeOptions"
                            :rules="validations.weighing_type"
                            label="Método de pesagem"
                            dense
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pb-0">
                          <v-textarea
                            v-model="form.info"
                            label="Observações"
                            dense
                            outlined
                            persistent-placeholder
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="12" xs="12">
                          <v-btn
                            block
                            color="success"
                            :loading="loading"
                            :disabled="checkUser()"
                            @click="add()"
                          >
                            adicionar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-card-text>
              </v-card>
            </v-form>
          </div>
        </v-col>

        <v-col md="8">
          <div>
            <div class="d-flex">
              <div class="mr-auto">
                <h2 class="mb-0 text--secondary">Lote {{ batch.code }}
                  <v-btn text icon class="mt-n3" :to="`/lotes/${id}/editar`">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </h2>
                <p class="text--secondary"><i>{{ batch.name }}</i></p>
              </div>
              <v-btn
                class="mt-4"
                color="green darken-3"
                elevation="1"
                large
                outlined
                :to="`/calculator?farm_id=${farm_id}&module_id=${module_id}`"
              > <h3>CALCULADORA</h3>
              </v-btn>
            </div>

            <v-alert
              border="left"
              colored-border
              color="green darken-4"
              elevation="1"
            >
              <v-row>
                <v-col lg="8" md="12">
                  <div class="body-1 d-flex justify-content-center align-items-center">
                    <v-icon class="title pr-2">mdi-account</v-icon> {{ batch.customer && batch.customer.name | defaultEmpty }}
                  </div>
                  <div class="body-1 d-flex justify-content-center align-items-center mt-2">
                    <v-icon class="title pr-2">mdi-barn</v-icon> {{ batch.module && batch.module.farm.name | defaultEmpty }}
                  </div>
                  <div class="body-1 d-flex justify-content-center align-items-center mt-2">
                    <v-icon class="title pr-2">mdi-cow </v-icon>
                    {{ animalLabel(batch) | defaultEmpty }}
                  </div>
                  <div v-if="supplementConsumption" class="body-1 d-flex justify-content-center align-items-center mt-2">
                      <v-icon class="title pr-2">mdi-food-takeout-box-outline</v-icon>
                      Suplementação de {{ supplementConsumption | number:decimals(3) | defaultEmpty }} kg de MN/cab/dia
                  </div>
                </v-col>
                <v-col lg="4" md="12">
                  <div class="body-1 d-flex justify-content-center align-items-center">
                    <v-icon class="title pr-2">mdi-view-module </v-icon>
                    {{ batch.module && batch.module.name | defaultEmpty }} 
                  </div>
                  <div class="body-1 d-flex justify-content-center align-items-center mt-2">
                    <v-icon class="title pr-2">mdi-map </v-icon>
                    {{ batch.module && batch.module.paddocks_aggregate.aggregate.sum.area | number:decimals(0) }} hectares / {{ batch.module && batch.module.paddocks_aggregate.aggregate.count }} piquetes
                  </div>
                  <div class="body-1 d-flex justify-content-center align-items-center mt-2">
                    <v-icon class="title pr-2">mdi-calendar-clock</v-icon> {{ daysOccupation[batch.day_occupation] | defaultEmpty }}
                  </div>
                </v-col>
              </v-row>
            </v-alert>

            <v-row>
              <v-col sm="4" >
                <v-card color="success darken-2" dark>
                  <v-card-title class="subtitle-2">Tamanho do lote</v-card-title>
                  <v-card-text>
                    <div class="py-3">
                      <h1 class="white--text">
                        {{ batch.number_of_heads | number:decimals(0) }} cabeças
                      </h1>

                      <v-avatar class="icon-bottom-card">
                        <v-icon>mdi-alert-circle-check</v-icon>
                      </v-avatar>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="4">
                <v-card color="success darken-1" dark>
                  <v-card-title class="subtitle-2">Desempenho previsto</v-card-title>
                  <v-card-text>
                    <div class="py-3">
                      <h1 class="white--text">
                        {{ batch.expected_performance | number:decimals(0) }} g/cab/dia
                      </h1>

                      <v-avatar class="icon-bottom-card">
                        <v-icon>mdi-abacus</v-icon>
                      </v-avatar>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="4">
                <v-card color="success" dark>
                  <v-card-title class="subtitle-2">Peso médio</v-card-title>
                  <v-card-text>
                    <div class="py-3">
                      <h1 class="white--text">{{ batch.average_weight | number:decimals(1) }} kg</h1>

                      <v-avatar class="icon-bottom-card">
                        <v-icon>mdi-anvil</v-icon>
                      </v-avatar>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="12">
                <h3 class="mb-3">Lote - Categorias</h3>

                <v-data-table
                  v-if="!loading"
                  :headers="headersAnimal"
                  :items="transactionsBalance"
                  :items-per-page="10"
                  sort-by="total_weight"
                  :sort-desc="true"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:item.animal="{ item }">
                    {{ animalLabel(item) }}
                  </template>
                  <template v-slot:item.number_of_heads="{ item }">
                    {{ item.number_of_heads | number:decimals(0) }} cab.
                  </template>
                  <template v-slot:item.average_weight="{ item }">
                    {{ transactionsBalance.length < 2 ? batch.average_weight : item.average_weight | number:decimals(1) }} kg
                  </template>
                  <template v-slot:item.gmd="{ item }">
                    {{ (transactionsBalance.length < 2 ? (batch.expected_performance > 0 ? batch.expected_performance : '---') : item.gmd > 0? item.gmd : '---') | number:decimals(0) }} g/cab/dia
                  </template>
                  <template v-slot:item.total_weight="{ item }">
                    {{ transactionsBalance.length < 2 ? batch.number_of_heads * batch.average_weight : item.number_of_heads * item.average_weight | number:decimals(0) }} kg
                  </template>
                </v-data-table>

              </v-col>
            </v-row>

            <v-row>
              <v-col sm="12">
                <div class="d-flex justify-space-between">
                  <h3 class="mb-3">Histórico</h3>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn text icon color="green darken-3" @click="exportBatch()" v-on="on">
                        <v-icon>mdi-microsoft-excel</v-icon>
                      </v-btn>
                    </template>
                    <span>Exportar Lote para Excel</span>
                  </v-tooltip>
                </div>

                <v-data-table
                  :headers="headers"
                  :items="transactions"
                  :items-per-page="10"
                  :single-expand="false"
                  :expanded.sync="transactionsExpanded"
                  sort-by="date"
                  :sort-desc="true"
                  show-expand
                  class="elevation-1"
                >
                  <template v-slot:item.event_date="{ item }">
                    {{ item.event_date | date }}
                  </template>
                  <template v-slot:item.type="{ item }">
                    {{ movementTypes[item.type] }}
                  </template>
                  <template v-slot:item.category="{ item }">
                    {{ item.type === 'SUPLEMENTACAO' ? (`${(item.supplement_mineral_consumption + item.supplement_feed_consumption + item.supplement_vol_consumption).toFixed(3)} ${supplementTypes.text}`.replace('.',',')) : movementCategories[item.category] || weighingTypes[item.weighing_type] || checkCategoryChange(item) || daysOccupation[item.day_occupation] }}
                  </template>
                  <template v-slot:item.number_of_heads="{ item }">
                    {{ item.batch_number_of_heads | number:decimals(0) }} cab.
                  </template>
                  <template v-slot:item.average_movemented_weight="{ item }">
                    {{ item.average_movemented_weight | number:decimals(1) }} kg
                  </template>
                  <template v-slot:item.batch_number_of_heads="{ item }">
                    {{ item.type === 'ENTRADA' || item.type === 'SAIDA' || item.type === 'MUDANÇA_DE_MODULO' ? item.number_of_heads : 0 | number:decimals(0) }} cab.
                  </template>
                  <template v-slot:item.average_weight="{ item }">
                    {{ item.batch_average_weight | number:decimals(1) }} kg
                  </template> 
                  <template v-slot:expanded-item="{ headers, item, index }">
                    <td :colspan="headers.length">
                      <v-card class="elevation-0 my-1">
                        <v-card-title>
                          <span>{{ showTranscationsCategory(item) }}</span>
                          <v-spacer/>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn v-if="(isManager || isConsultant)" icon @click="recalculateTransaction(item,index)" v-on="on">
                                <v-icon>mdi-reload</v-icon>
                              </v-btn>
                            </template>
                            <span>Recalcular Dados de Desempenho</span>
                          </v-tooltip>
                          <v-btn v-if="lastTransaction.id === item.id && (isManager || isConsultant)" icon @click="remove(item)">
                              <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col sm="6">
                              <v-simple-table dark dense class="grey lighten-3 black--text">
                                <template v-slot:default>
                                  <thead class="grey lighten-2">
                                    <tr>
                                      <th colspan="2" class="subtitle-2 black--text">Movimentação</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th>Intervalo desde última movimentação</th>
                                      <td>{{ item.interval | number:decimals(0) | defaultEmpty }} dias</td>
                                    </tr>
                                    <tr>
                                      <th>Cabeças movimentadas</th>
                                      <td>{{ item.number_of_heads | number:decimals(0) | defaultEmpty }} cab</td>
                                    </tr>
                                    <tr>
                                      <th>Peso médio movimentado</th>
                                      <td>{{ item.average_movemented_weight | number:decimals(1) | defaultEmpty }} kg/cab</td>
                                    </tr>
                                    <tr>
                                      <th>Tamanho do lote atual</th>
                                      <td>{{ item.batch_number_of_heads }} cab</td>
                                    </tr>
                                    <tr>
                                      <th>Peso médio atual</th>
                                      <td>{{ item.batch_average_weight | number:decimals(1) | defaultEmpty }} kg/cab</td>
                                    </tr>
                                    <tr v-if="item.type === 'ENTRADA'|| item.type === 'SAIDA'|| item.type === 'PESAGEM'">
                                      <th>Método de pesagem</th>
                                      <td>{{ item.weighing_type.replaceAll('_',' ') | defaultEmpty }}</td>
                                    </tr>
                                    <tr v-if="item.type === 'ENTRADA'|| item.type === 'SAIDA'|| item.type === 'PESAGEM'">
                                      <th>Data da pesagem anterior</th>
                                      <td>{{ item.last_weighing_date | date | defaultEmpty }}</td>
                                    </tr>
                                    <tr v-if="item.type === 'ENTRADA'|| item.type === 'SAIDA'|| item.type === 'PESAGEM'">
                                      <th>Peso total anterior</th>
                                      <td>{{ item.last_weighing | number:decimals(0) | defaultEmpty }} kg</td>
                                    </tr>
                                    <tr>
                                      <th>Peso total movimentado</th>
                                      <td>{{ (item.average_movemented_weight? item.average_movemented_weight * item.number_of_heads : '') | number:decimals(0) | defaultEmpty }} kg</td>
                                    </tr>
                                    <tr>
                                      <th>Peso total saldo</th>
                                      <td>{{ item.batch_total_weight | number:decimals(0) }} kg</td>
                                    </tr>
                                    <tr v-if="item.type === 'ENTRADA'|| item.type === 'SAIDA'|| item.type === 'PESAGEM'">
                                      <th>Intervalo desde última pesagem</th>
                                      <td> {{ checkIntervalLastWeighning(item) }} dias </td>
                                    </tr>
                                    <tr v-if="(item.type === 'ENTRADA'|| item.type === 'SAIDA'|| item.type === 'PESAGEM')">
                                      <th>Diárias desde última pesagem</th>
                                      <td>{{ item.dailys_since_last_weighing | number:noRound() }} diárias</td>
                                    </tr>
                                    <tr>
                                      <th>Período desde o início</th>
                                      <td>{{ checkIntervalSinceBeggining(item) }} dias</td>
                                    </tr>
                                    <tr>
                                      <th>Diárias desde o ínicio</th>
                                      <td>{{ item.dailys_since_beginning | number:noRound() }} diárias </td>
                                    </tr>
                                    <tr v-if="(item.type === 'ENTRADA'|| item.type === 'SAIDA'|| item.type === 'PESAGEM')">
                                      <th>Somatório atual de saídas</th>
                                      <td>{{ item.sum_departure | number:noRound() }} kg</td>
                                    </tr>
                                    <tr v-if="(item.type === 'ENTRADA'|| item.type === 'SAIDA'|| item.type === 'PESAGEM')">
                                      <th>Somatório atual das entradas</th>
                                      <td>{{ item.sum_entry | number:noRound() }} kg</td>
                                    </tr>
                                    <tr v-if="item.tranferred_batch">
                                      <th>Lote Referência</th>
                                      <td>{{ getTransferredBatchName(item.transferred_batch) | defaultEmpty }}</td>
                                    </tr>
                                    <tr v-if="item.current_module !== getModuleText(item.transferred_module)">
                                      <th>Módulo de Origem</th>
                                      <td>{{ getModuleText(item.transferred_module) | defaultEmpty }}</td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                              <br/>
                              <v-simple-table dense class="blue lighten-4">
                                <template v-slot:default>
                                </template>
                              </v-simple-table>
                              <h3 class="mt-4">
                                {{ item.info }}
                              </h3>
                            </v-col>
                            <v-col sm="6">
                              <v-row>
                                <v-col>
                                  <v-simple-table dark dense class="light-green lighten-1 black--text">
                                    <template v-slot:default>
                                      <thead class="green">
                                        <tr>
                                          <th colspan="2" class="subtitle-2 black--text">Pasto e Ocupação</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th>Módulo atual</th>
                                          <td>{{ item.current_module }}</td>
                                        </tr>
                                        <tr>
                                          <th>Área do módulo em uso</th>
                                          <td> {{ item.module_area | number:decimals(0) | defaultEmpty }} hectares </td>
                                        </tr>
                                        <tr>
                                          <th>Piquetes em uso</th>
                                          <td>{{ item.paddocks_in_use | number:decimals(0) | defaultEmpty }}</td>
                                        </tr>
                                        <tr>
                                          <th>Tempo de ocupação</th>
                                          <td>{{ daysOccupation[item.day_occupation] | defaultEmpty }} </td>
                                        </tr>
                                        <tr>
                                          <th>Tempo de descanso</th>
                                          <td>{{ item.rest_time | number:decimals(0) | defaultEmpty }} dias </td>
                                        </tr>
                                        <tr>
                                          <th>Matéria Seca Disponível</th>
                                          <td>{{ item.available_dry_matter | number:decimals(0) | defaultEmpty }} kg/piquete</td>
                                        </tr>
                                        <tr>
                                          <th>Proteína</th>
                                          <td>{{ item.crude_protein * 100 | number:decimals(1) | defaultEmpty }} %</td>
                                        </tr>
                                        <tr>
                                          <th>Fibra</th>
                                          <td>{{ item.fiberIn_neutral_detergent * 100 | number:decimals(1) | defaultEmpty }} %</td>
                                        </tr>
                                        <tr>
                                          <th>Energia</th>
                                          <td>{{ item.metabolizable_energy | number:decimals(2) | defaultEmpty }} Mcal/kg</td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                  <br/>
                                  <v-simple-table dense class="yellow lighten-3 black--text">
                                    <template v-slot:default>
                                      <thead class="amber accent-3 black--text">
                                        <tr>
                                          <th colspan="2" class="subtitle-2 black--text">Indicadores de Desempenho</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th>Lotação</th>
                                          <td>{{ item.module_capacity | number:decimals(1) | defaultEmpty }} UA/ha</td>
                                        </tr>
                                        <tr>
                                          <th>Carga Instantânea</th>
                                          <td>{{ item.instant_charge | number:decimals(1) | defaultEmpty }} UA/ha</td>
                                        </tr>
                                        <tr>
                                          <th>Consumo esperado</th>
                                          <td>{{ item.expected_consumption | number:decimals(2) | defaultEmpty }} kg MS/cab/dia</td>
                                        </tr>
                                        <tr>
                                          <th>Consumo / Peso vivo</th>
                                          <td>{{ item.consumption_by_live_weight | percent(2) | defaultEmpty }}</td>
                                        </tr>
                                        <tr>
                                          <th>Desempenho estimado atual</th>
                                          <td>{{ item.expected_performance | number:decimals(0) | defaultEmpty }} g/cab/dia</td>
                                        </tr>
                                        <tr class="yellow lighten-1 black--text">
                                          <th>Desempenho Real Atual</th>
                                          <td>{{ item.real_performance | number:decimals(0) | defaultEmpty }} g/cab/dia</td>
                                        </tr>
                                        <tr class="yellow lighten-1 black--text">
                                          <th>Desempenho médio geral</th>
                                          <td>{{ item.average_real_performance | number:decimals(0) | defaultEmpty }} g/cab/dia</td>
                                        </tr>
                                        <tr class="yellow lighten-1 black--text">
                                          <th>Produtividade real</th>
                                          <td>{{ item.real_arrobas_produced | number:decimals(2) | defaultEmpty }} (@/ha/mês)</td>
                                        </tr>
                                        <tr>
                                          <th>Produtividade estimada</th>
                                          <td>{{ item.arrobas_produced | number:decimals(2) | defaultEmpty }} (@/ha/mês)</td>
                                        </tr>
                                      </tbody>
                                      <tbody>
                                        <th></th>
                                        <td></td>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                  <br>
                                  <v-simple-table dense class="blue lighten-4">
                                    <template v-slot:default>
                                      <thead class="blue lighten-2">
                                        <tr v-if="item.supplement_mineral_consumption
                                        || item.supplement_feed_consumption
                                        || item.supplement_vol_consumption">
                                          <th colspan="2" class="subtitle-2 black--text">Suplementação</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <th v-if="item.supplement_mineral_consumption">Proteinado/Energético </th>
                                        <tr v-if="item.supplement_mineral_consumption">
                                          <th>Consumo</th>
                                          <td>{{ (item.supplement_mineral_consumption * 1000) | number:decimals(0) }} g/cab/dia</td>
                                        </tr>
                                        <tr v-if="item.supplement_mineral_consumption">
                                          <th>Teor de PB</th>
                                          <td>{{ item.supplement_mineral_pb | number:decimals(1) }}%</td>
                                        </tr>
                                        <tr v-if="item.supplement_mineral_consumption">
                                          <th>Teor de NDT </th>
                                          <td>{{ item.supplement_mineral_ndt | number:decimals(1) }}%</td>
                                        </tr>
                                        <tr v-if="item.supplement_mineral_description">
                                          <th>Descrição </th>
                                          <td>{{ item.supplement_mineral_description }}</td>
                                        </tr>
                                        <th v-if="item.supplement_feed_consumption">Ração Concentrada </th>
                                        <tr v-if="item.supplement_feed_consumption">
                                          <th>Consumo</th>
                                          <td>{{ (item.supplement_feed_consumption * 1000 ) | number:decimals(0) }} g/cab/dia </td>
                                        </tr>
                                        <tr v-if="item.supplement_feed_consumption">
                                          <th>Teor de PB</th>
                                          <td>{{ item.supplement_feed_pb | number:decimals(1) }}% </td>
                                        </tr>
                                        <tr v-if="item.supplement_feed_consumption">
                                          <th>Teor de NDT</th>
                                          <td>{{ item.supplement_feed_ndt | number:decimals(1) | defaultEmpty }} %</td>
                                        </tr>
                                        <tr v-if="item.supplement_feed_consumption">
                                          <th>Teor de FDN</th>
                                          <td>{{ item.supplement_feed_fdn | number:decimals(1) | defaultEmpty }} %</td>
                                        </tr>
                                        <tr v-if="item.supplement_feed_description">
                                          <th>Descrição </th>
                                          <td>{{ item.supplement_feed_description }}</td>
                                        </tr>
                                        <th v-if="item.supplement_vol_consumption">Volumoso</th>
                                        <tr v-if="item.supplement_vol_consumption">
                                          <th>Consumo</th>
                                          <td>{{ (item.supplement_vol_consumption * 1000) | number:decimals(0) | defaultEmpty }} g/cab/dia</td>
                                        </tr>
                                        <tr v-if="item.supplement_vol_consumption">
                                          <th>Teor de MS</th>
                                          <td>{{ item.supplement_vol_ms | number:decimals(1) | defaultEmpty }} %</td>
                                        </tr>
                                        <tr v-if="item.supplement_vol_consumption">
                                          <th>Teor de PB</th>
                                          <td>{{ item.supplement_vol_pb | number:decimals(1) | defaultEmpty }} %</td>
                                        </tr>
                                        <tr v-if="item.supplement_vol_consumption">
                                          <th>Teor de EM</th>
                                          <td>{{ item.supplement_vol_em | number:decimals(0) | defaultEmpty }} Mcal/kg</td>
                                        </tr>
                                        <tr v-if="item.supplement_vol_consumption">
                                          <th>Teor de FDN</th>
                                          <td>{{ item.supplement_vol_fdn | number:decimals(1) | defaultEmpty }} %</td>
                                        </tr>
                                        <tr v-if="item.supplement_vol_description">
                                          <th>Descrição </th>
                                          <td>{{ item.supplement_vol_description }}</td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </td>
                  </template>
                </v-data-table>

              </v-col>
            </v-row>

            <v-row>
              <v-col class="sm-8">
                <v-card v-if="$vuetify.breakpoint.xs? false:true">
                  <v-card-title class="title-1">Aviso legal</v-card-title>
                  <v-card-text>
                    <span>
                      Nenhum conteúdo do Pastoreio de Precisão deve ser considerado como recomendação de diminuição/manutenção/aumento da quantidade de animais num lote específico ou na fazenda inteira. Tampouco como garantia de que será alcançado ou deixado de alcançar determinado desempenho por parte do gado.
                      Deve, sim, ser considerado como o processamento de dados provenientes de imagens de satélite e sua tradução em números que auxiliam na tomada de decisão.
                    </span>
                  </v-card-text>
                </v-card>
                <v-expand-transition v-if="$vuetify.breakpoint.xs? true:false">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-card>
                        <v-expansion-panel-header>
                          <v-card-title class="title-1">Aviso legal</v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card-text>
                            <span>
                              Nenhum conteúdo do Pastoreio de Precisão deve ser considerado como recomendação de diminuição/manutenção/aumento da quantidade de animais num lote específico ou na fazenda inteira. Tampouco como garantia de que será alcançado ou deixado de alcançar determinado desempenho por parte do gado.
                              Deve, sim, se considerado como o processamento de dados provenientes de imagens de satélite e sua tradução em números que auxiliam na tomada de decisão.
                            </span>              
                          </v-card-text>
                        </v-expansion-panel-content>
                      </v-card>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expand-transition>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.icon-bottom-card {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 2.5rem;
}
</style>